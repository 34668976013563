import React, {useState} from 'react';

const Checkbox = props => {
  const { label, ...attrs } = props;

  return (
    <label className="flex items-center">
      <input {...attrs} className="mr-3 w-5 h-5" />
      {label}
    </label>
  )
}

const TextInput = props => {
  const { label, value: valFromProps, disabled, ...attrs } = props;
  const [value, setValue] = useState(valFromProps || '');

  const handleChange = (evt) => {
    if (props.onChange) { props.onChange(evt) }
    setValue(evt.target.value)
  }

  return (
    <>
      <div>
        <label htmlFor={name} className="w-full">{label}</label>
      </div>
      <div>
        <input type="text" {...attrs} onChange={handleChange}value={value} className={`border border-black w-full p-2 rounded ${disabled ? 'bg-gray-200' : ''}`} placeholder={disabled ? 'Disabled' : ''} />
      </div>
    </>
  )
}

const TextArea = props => {
  const { label, value: valFromProps, className, border = true, ...attrs } = props;
  const [value, setValue] = useState(valFromProps || '');

  const handleChange = (evt) => {
    if (props.onChange) { props.onChange(evt) }
    setValue(evt.target.value)
  }

  return (
    <>
      <div>
        <label htmlFor={name} className="w-full">{label}</label>
      </div>
      <div>
        <textarea {...attrs} onChange={handleChange} className={`dark:bg-zinc-900 outline-none w-full p-2 block ${border ? 'border border-black rounded' : ''} ${className}`} />
      </div>
    </>
  )
}

const FileInput = props => {
  const { label, ...attrs } = props;
  return (
    <>
      <div>
        <label htmlFor={name} className="w-full">{label}</label>
      </div>
      <div>
        <input {...attrs} type="file" />
      </div>
    </>
  )
}

const PasswordInput = props => {
  const { ...attrs } = props;
  return <TextInput type="password" {...attrs} />
}

const HiddenInput = props => {
  return (
    <input type="hidden" {...props} />
  )
}

export const Field = props => {
  const { errors, label, note, ...attrs } = props;

  let component;
  switch(attrs.type) {
    case "checkbox":
      component = <Checkbox label={label} {...attrs} />
      break;
    case "textarea":
      component = <TextArea label={label} {...attrs} />
      break;
    case "text":
      component = <TextInput label={label} {...attrs} />
      break;
    case "file":
      component = <FileInput label={label} {...attrs} />
      break;
    case "password":
      component = <PasswordInput label={label} {...attrs} />
      break;
    case "hidden":
      component = <HiddenInput {...attrs} />
      return component; // No need for rendering below with more layout

  }

  return (
    <div>
      {component}
      {errors?.length > 0 && <ul>
        {errors.map((err) => <li>{err}</li>)}
      </ul>}
      {note && <div className="text-sm italic text-gray-600">{note}</div>}
    </div>
  )
}
