import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Link, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import {Nav} from "./nav";
import {Card as CardLayout} from "./layouts/card";

import {useAuth} from "../contexts/auth_context";
import {AuthCard} from "./users/auth_card";
import { Avatar } from "./users/avatar";
import { usePopper } from "react-popper";
import {ModalLayout, WithModal} from "./layouts/modal";
import {Index} from "./users/settings";
import {popper} from "@popperjs/core";
import {buildFlash, Flash} from "./flash";
import {useConfig} from "../contexts/config_context";
import {HomeIcon} from "./icons/home";
import {LogoutIcon} from "./icons/logout";
import {LockIcon} from "./icons/lock";
import {SignIn} from "./users/sign_in";
import {SignInIcon} from "./icons/sign_in";
import {SettingsIcon} from "./icons/settings";
import {PlusIcon} from "./icons/plus";
import {ProfileIcon} from "./icons/profile";
import {UserIcon} from "./icons/user";
import ChessieStencilRepeatInverted from '../assets/images/chessie-stencil-repeat-inverted.png'
import SnootIcon from '../assets/images/snoot.png'

const messagesIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
</svg>;
const notificationsIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
</svg>
const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
const lockIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
        d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"/>
</svg>;
const profileIcon = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
</svg>
const addButton = <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
</svg>

const CircularButton = React.forwardRef(({ children, pressed: incomingPressed, ...attrs }, ref) => {
  const [pressed, setPressed] = useState(incomingPressed);

  useEffect(() => {
    setPressed(incomingPressed)
  }, [incomingPressed])

  const togglePressed = (evt) => {
    evt.preventDefault();
    setPressed(!pressed)
  }

  const pressedClassNames = [
    pressed ? 'scale-90' : 'scale-100',
    pressed ? 'border-black border-2' : 'border-2 border-black'
  ]

  return (
    <button type="button"
      onMouseDown={(evt) => togglePressed(evt)}
      className={`select-none duration-150 rounded-full ${pressedClassNames.join(' ')} w-16 h-16 flex items-center w-full justify-center shadow-md bg-white`}
      ref={ref}
      {...attrs}>
      {children}
    </button>
  )
})

const Lock = props => {
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <CircularButton onClick={() => navigate('/sign-in')}>
        {lockIcon}
      </CircularButton>
      <ModalLayout open={openModal}>
        <AuthCard />
      </ModalLayout>
    </>
  )
}

const ActionButton = props => {
  const { user } = useAuth();
  const [showPopUp, setShowPopUp] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      { name: 'offset', options: { offset: [0, 20], } }
    ]
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (popperElement && referenceElement) {
      document.addEventListener('click', evt => {
        const {target} = evt;

        if (target !== popperElement && target !== referenceElement && !referenceElement.contains(target) && !popperElement.contains(target)) {
          setShowPopUp(false)
        }
      })
    }
  }, [popperElement, referenceElement])

  const buildButton = ({icon, label, onClick}) => <li className="flex items-center p-4 border-black border-2 rounded-full bg-white shadow-md shadow-gray-600" onClick={onClick}>
    <div className="mr-4">
      {icon}
    </div>
    <div>
      <div>
        {label}
      </div>
    </div>
  </li>

  return <div>
    <CircularButton onClick={() => setShowPopUp(!showPopUp)} ref={setReferenceElement} pressed={showPopUp}>
      <Avatar expanded={false} user={user} size='md' />
    </CircularButton>
    {showPopUp && <div ref={setPopperElement} className="min-w-fit" style={styles.popper} {...attributes.popper}>
      <ul className="flex flex-col gap-5 whitespace-nowrap">
        {/*{buildButton(profileIcon, 'Profile')}*/}
        {buildButton({
          icon: settingsIcon,
          label: 'Settings',
          onClick: () => {
            setShowPopUp(false);
            navigate('/my/settings')
          }
        })}
        {/*{buildButton(messagesIcon, 'DMs')}*/}
        {/*{buildButton(notificationsIcon, 'Notifications')}*/}
        {user.admin && buildButton({
          icon: addButton,
          label: 'New Post',
          onClick: () => {
            setShowPopUp(false);
            navigate('/posts/new')
          }
        })}
      </ul>
    </div>}
  </div>
}

// const ActionBar = props => {
//   const { user } = useAuth();
//   const [referenceElement, setReferenceElement] = useState(null);
//   const [popperElement, setPopperElement] = useState(null);
//   const [arrowElement, setArrowElement] = useState(null);
//   const { styles, attributes } = usePopper(referenceElement, popperElement, {
//     placement: 'top',
//     modifiers: [
//       { name: 'arrow', options: { element: '[data-popper-arrow]' } },
//       { name: 'offset', options: { offset: [0, 10], } }
//     ],
//   });
//
//   return (
//     <div className="sticky bottom-10 md:w-3/4 lg:w-1/2 mx-auto">
//       {user ?
//         <div className="flex px-6">
//           <CircularButton onClick={() => setShowPopUp(!showPopUp)} ref={setReferenceElement}>
//             <Avatar expanded={false} user={user} size={'7'} />
//           </CircularButton>
//           <div className="flex justify-end w-full">
//             <div className={`shadow-gray-400 flex w-full py-4 px-6 bg-white transition-all border-2 border-black rounded-full shadow-md mx-2 z-10`}>
//               <div className="flex w-full items-center">
//                 <div className="flex gap-6 w-full">
//                   { showPopUp && <div role="tooltip" tabIndex="-1" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
//                     <CardLayout className="p-4 bg-white">
//                       <ul className="flex items-center gap-6">
//                         <li>
//                           <WithModal button={settingsIcon}>
//                             <Index />
//                           </WithModal>
//                         </li>
//                         <li>{messagesIcon}</li>
//                         <li>{notificationsIcon}</li>
//                       </ul>
//                     </CardLayout>
//                     <div data-popper-arrow style={styles.arrow} />
//                   </div> }
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>:
//         <div className="flex justify-end w-full">
//           <div className="pr-6">
//           <Lock />
//           </div>
//         </div>
//       }
//     </div>
//   )
// }

const UserButton = props => {
  const { buttonStyles } = props;
  const [showPopUp, setShowPopUp] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      { name: 'offset', options: { offset: [0, 20], } }
    ]
  });

  const buildButton = ({icon, label, onClick}) => <li className="flex items-center p-4 border-black border-2 rounded-full bg-white shadow-md shadow-gray-600" onClick={onClick}>
    <div className="mr-4">
      {icon}
    </div>
    <div>
      <div>
        {label}
      </div>
    </div>
  </li>;

  useEffect(() => {
    if (popperElement && referenceElement) {
      document.addEventListener('click', evt => {
        const {target} = evt;

        if (target !== popperElement && target !== referenceElement && !referenceElement.contains(target) && !popperElement.contains(target)) {
          setShowPopUp(false)
        }
      })
    }
  }, [popperElement, referenceElement]);

  return (
    <>
      <button type="button" onClick={() => setShowPopUp(!showPopUp)} ref={setReferenceElement}>
        <UserIcon />
      </button>
      {showPopUp && <div ref={setPopperElement} className="min-w-fit border border-1 border-black" style={styles.popper} {...attributes.popper}>
        <ul className="flex flex-col gap-5 whitespace-nowrap">
          {buildButton({
            icon: settingsIcon,
            label: 'Settings',
            onClick: () => {
              setShowPopUp(false);
              navigate('/my/settings')
            }
          })}
        </ul>
      </div>}
    </>
  )
}

const ActionBar = props => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location)

  const button = <div className={`bg-white border-2 border-black p-3 rounded-full shadow-black shadow-xl`}>
    <img src={`/assets/${SnootIcon}`} className="size-12" />
  </div>

  return <div className="mx-auto fixed bottom-5 z-10">
    {user
      ? (
        location.pathname.includes('/my/settings') ?
        <button className="block" onClick={() => navigate('/')}>{button}</button> :
        <button className="block" onClick={() => navigate('/my/settings')}>{button}</button>
      )
      : <WithModal
      button={button}>
      {({ setShowModal }) => <AuthCard onSignIn={setShowModal} />}
    </WithModal>}
  </div>
}


export const Layout = props => {
  const { user } = useAuth()
  const location = useLocation();

  const flash = location?.state?.flash;

  return (
    <div
        className={"fixed bg-black flex justify-center h-full w-full overflow-scroll"}
        style={{
          backgroundImage: `url("/assets/${ChessieStencilRepeatInverted}")`,
          backgroundSize: '230px'
        }}
    >
      <div className={"mx-auto w-full md:w-3/4 lg:w-1/2 pb-32"}>
        <div className="flex flex-col">
          <Nav />
          <div className="justify-center mx-auto w-full md:w-5/6 lg:w-5/6 pb-28 px-6">
            <div className="mb-10">
              {flash && <Flash flash={flash} />}
            </div>
            <Outlet />
          </div>
        </div>
      </div>
      <ActionBar />
    </div>
  )
}
